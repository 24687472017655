.login-page {
  height: 100vh;
  width: 100vw;

  .company-logo {
    max-width: 404px;
  }

  .form-container {
    max-width: 500px;
  }
}
