$primary: #004C9C !default;
$secondary: #0091b8 !default;
$dark-gray: #b2b2b2 !default;
$gray: #687182 !default;
$light-gray: #F7F9FC !default;
$light-green: #009384 !default;
$fade-gray: #464F60;
$green: #14804A !default;
$light-blue: #E9F4FF !default;
$black: #000000 !default;
$danger: #FF0101 !default;

$link-hover: #006985 !default;

$colors: (
        "primary": $primary,
        "secondary": $secondary,
        "dark-gray": $dark-gray,
        "light-green": $light-green,
        "light-gray": $light-gray,
        "fade-gray": $fade-gray,
        "green": $green,
        "gray": $gray,
        "light-blue" : $light-blue,
        "black": $black,
        "danger": $danger
);

@each $color-name, $color-code in $colors {
  .text-#{$color-name} {
    color: $color-code !important;
  }
}

@each $color-name, $color-code in $colors {
  .bg-#{$color-name} {
    background-color: $color-code !important;
  }
}

@each $color-name, $color-code in $colors {
  .border-#{$color-name} {
    border-color: $color-code !important;
  }
}
