@import './custom.scss';
@import "./bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

html {
  overflow: hidden;
}

body {
  font-family: 'Roboto', sans-serif;
}

p {
  font-size: 16px;
  line-height: 19px;
}

h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
}

h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

h3 {
  font-size: 1.125rem;
  font-weight: bold;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.75rem;
}

h6 {
  font-size: 0.625rem;
}

$font-sizes: (
        "small": small,
        "smaller": smaller,
        "10": 10px,
        "12": 12px,
        "13": 13px,
        "14": 14px,
        "15": 15px,
        "16": 16px,
        "20": 20px,
        "22": 22px,
        "24": 24px,
        "36": 36px,
        "48": 48px
);

$font-weight: (
        "400": 400,
        "500": 500,
        "700": 700
);

@each $font-name, $font-code in $font-sizes {
  .font-#{$font-name} {
    font-size: $font-code !important;
  }
}

@each $font-name, $font-code in $font-weight {
  .font-weight-#{$font-name} {
    font-weight: $font-code !important;
  }
}

$cursor-values: (
        "pointer": pointer,
        "not-allowed": not-allowed,
        "grab": grab
);
@each $name, $value in $cursor-values {
  .c-#{$name} {
    cursor: $value;
  }
}

.link-hover {
  color: $primary;
  cursor: pointer;

  &:hover {
    color: $link-hover;
    text-decoration: underline;
  }
}

.stick {
  &-top {
    top: 0 !important;
  }

  &-bottom {
    bottom: 0 !important;
  }

  &-left {
    left: 0 !important;
  }

  &-right {
    right: 0 !important;
  }
}

.sort-btn {
  display: flex;
  align-items: center;
  height: 15px;

  &.active-sort{
    cursor: pointer;
  }

  svg {
    display: block;
    width: 20px;
    height: 22px;
    transition: all 0.2s ease;

    &:first-of-type {
      fill: $fade-gray;
    }

    &:last-of-type {
      margin-top: -16px;
      fill: #A1A9B8;
    }
  }
}

.sort-btn.active-sort {
  svg {
    &:first-of-type {
      fill: #A1A9B8;
    }

    &:last-of-type {
      fill: $fade-gray;
    }
  }
}

.tooltip-icon {
  width: 12px;
  height: 12px;
  position: relative;
  top: -2px;
  right: -5px;
  cursor: pointer;
}

.status-label, .method-label {
  padding: 1px 8px;
  border-radius: 4px;

  .circle {
    width: 6px;
    height: 6px;
    margin-right: 6px;
    margin-bottom: 1px;
    border-radius: 2px;
  }
}

.status-label.up-to-date {
  color: #14804A;
  background-color: rgba(0, 147, 132, 0.08);

  .circle {
    background-color: #14804A;
  }
}

.status-label.modified {
  color: #D1293D;
  background-color: rgba(255, 237, 239, 1);

  .circle {
    background-color: #D1293D;
  }
}

.status-label.file-missing {
  color: #FF0B0B;
  background-color:  rgba(147, 0, 0, 0.29);

  .circle {
    background-color: #FF0B0B;
  }
}

.method-label.manual {
  color: $primary;
  background-color: rgba(0, 76, 156, 0.08);

  .circle {
    background-color: $primary;
  }
}

.method-label.automatic {
  color: #E07F24;
  background-color: rgba(239, 158, 84, 0.15);

  .circle {
    background-color: #E07F24;
  }
}

.update-label {
  svg {
    position: relative;
    top: -2px;
    width: 15px;
    margin-right: 7px;
  }
}

nav.breadcrumb {
  .active {
    color: $primary;
  }
}
