.table-preview-container {
  padding: 5px 100px 5px 5px;
}

.table-preview {
  display: table;
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  color: #666;
  background-color: #fff;
  margin-bottom: 20px;
}

.table__helper-col {
  width: 0;
  position: relative;
  padding: 0;
}

.table__helper {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 128px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 0;
  align-items: center;
  justify-content: space-evenly;

  button {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    color: #666;

    :hover {
      color: #000;
    }
  }
}

.table__row:hover .table__helper {

  display: flex !important;

}

.table__input {
  border-radius: 0;
  width: 100%;
  border: none;
  background-color: transparent;
  outline-offset: 1.5px;
}

.table__helper-btn {
  border-radius: 0;
  width: 100%;
  border: none;
  background-color: transparent;
  outline-offset: 1.5px;
}

.table__add-row {
  border-radius: 0;
  width: 100%;
  border: none;
  background-color: transparent;
  outline-offset: 1.5px;
  color: #ccc;

  :hover {
    color: #000;
  }
}

th {
  position: relative;
}

th > .resizeTrigger {
  content: '';
  position: absolute;
  display: block;
  width: 8px;
  right: -4px;
  top: 0;
  height: 100%;
  cursor: ew-resize;
}
