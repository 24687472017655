.Backdrop {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgb(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);
}

.TextCenter {
  width: 100%;
  position: relative;
  height: 100%;
}

.SpinnerBorder {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(50% - (58px / 2));
  right: calc(50% - 130px);
  color: black;
  margin-bottom: 10px;
}

.LoadingText {
  font-size: 18px;
  font-weight: 400;
  text-align: -webkit-center;
  margin-top: 5px;
}
