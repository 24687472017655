@import 'styles/custom.scss';

$frame-side-width: 72px;
$frame-header-height: 56px;
$icon-container-size: 40px;
$logo-size: 50px;
$icon-color: $primary;
$icon-color-solid: white;
$notifications-width: 20px;
$notifications-height: 20px;
$user-img-size: 32px;
$user-popup-color: rgb(223, 223, 223);

.app-frame {
  .frame-side {
    height: 100vh;
    width: $frame-side-width;

    .frame-nav-logo {
      width: $logo-size;
      height: $logo-size;
      object-fit: contain;
    }

    .frame-icon {
      border: 2px solid $icon-color;
      color: $icon-color;
      border-radius: 10px;
      width: $icon-container-size;
      height: $icon-container-size;
      margin: auto;
      align-items: center;
      justify-content: center !important;
      display: flex !important;
      margin-bottom: 10px;

      &-solid {
        background-color: $icon-color;
        color: $icon-color-solid;
      }

      &:last-child {
        margin-bottom: 19px;
      }
    }

    .bottom-icon {
      margin-left: calc((100% - $icon-container-size) / 2);
    }
  }

  .frame-header {
    padding: 12px 20px !important;
    border: 1px solid rgba(lightgray, .5);
    height: $frame-header-height;
    width: calc(100vw - $frame-side-width);
    margin-left: $frame-side-width !important;
    z-index: 1;

    .user-block {
      .user-popup {
        top: $frame-header-height;
        left: 5px;
        background-color: $user-popup-color;

        &::before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          border-right: 8px solid transparent;
          border-left: 8px solid transparent;
          border-bottom: 8px solid $user-popup-color;
          left: 55px;
          top: -7px;
        }

        .user-popup-option {
          letter-spacing: .65px;

          &:hover {
            color: #6c757d;
          }
        }
      }

      .user-image {
        width: $user-img-size;
        height: $user-img-size;
      }
    }
  }

  .frame-content {
    padding-left: $frame-side-width;
    padding-top: $frame-header-height;
    height: 100vh !important;
  }
}

.notifications-count {
  width: max-content;
  min-width: $notifications-width;
  height: $notifications-height;
  padding: 0 5px;
  top: -10px;
  right: -10px;
  border-radius: 10px;
  background-color: #D1293D;
  color: white;
  font-size: 12px;
  font-weight: 500;
  -webkit-animation: turnUp .1s;
  animation: turnUp .1s;
}

@-webkit-keyframes turnUp {
  0% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0)
  }

  25% {
    -webkit-transform: scale(0.25, 0.25);
    transform: scale(0.25, 0.25);
  }

  50% {
    -webkit-transform: scale(0.50, 0.50);
    transform: scale(0.50, 0.50);
  }

  75% {
    -webkit-transform: scale(0.75, 0.75);
    transform: scale(0.75, 0.75);
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes turnUp {
  0% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0)
  }

  25% {
    -webkit-transform: scale(0.25, 0.25);
    transform: scale(0.25, 0.25);
  }

  50% {
    -webkit-transform: scale(0.50, 0.50);
    transform: scale(0.50, 0.50);
  }

  75% {
    -webkit-transform: scale(0.75, 0.75);
    transform: scale(0.75, 0.75);
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.navTooltip.show {
  pointer-events: auto !important;
  z-index: 2;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.nav-divider {
  width: 100%;
  height: 3px;
  margin-top: 20px;
  background-color: #aab2b8;
}