// .background-plane {
//     top: 0;
//     left: 0;
//     width: inherit;
//     height: 100%;
//     background-repeat: no-repeat !important;
//     background-size: cover !important;
//     background-position: center !important;
//     z-index: -1;
// }

.background-colors {
  .background-colors-plane {
    z-index: -1;
  }

  .background-colors-content,
  .background-colors-plane {
    top: 0;
    left: 0;
    width: inherit;
    height: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
  }
}
