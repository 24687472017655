.asset-block-comp {
  .search-input {
    border-color: hsl(0, 0%, 80%);
  }

  .preview-container {
    margin: 20px;
    align-items: center;

    img, canvas {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}

.folder-selector {
  width: auto;
  border: 1px solid #cccccc;
  border-right: 0;
  padding: 5px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  white-space: nowrap;
}
