.Checkbox{
    position: relative;
    margin-bottom: 1rem;
}

.CheckboxInput {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
}

.CheckboxLabel {
    padding-left: 20px;

    font-size: 1rem;
    color: #444;
    cursor: pointer;
}

.CheckboxLabel:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;

    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 3px;

    position: absolute;
    top: 4px;
    left: 0;
    z-index: 1;

    transition: background .1s linear, border .1s linear;
}

.CheckboxLabel:after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;

    background: url("check.svg") no-repeat;
    background-size: 12px 12px;
    opacity: 0;

    position: absolute;
    top: 5px;
    left: 2px;
    z-index: 2;

    transition: opacity .1s linear;
}

.CheckboxInput:checked + .CheckboxLabel:before {
    background-color: #004C9C;
    border-color: #003f83;
}

.CheckboxInput:checked + .CheckboxLabel:after {
    opacity: 1;
}
