/* ! ALL :: IMG */
img {
  display: block;
  max-width: 100%;
}

/* ! ALL :: PAGE */
.page {
  position: relative;
  background: #0aa28f;
  height: 100vh;
  overflow-y: auto;
  color: #fff;
  font-size: 14px;
  line-height: 1.2;
  font-family: Calibri, sans-serif;
  font-weight: normal;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.container {
  max-width: 1200px;
  width: 100%;
  padding: 0 25px;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .container {
    padding: 0 50px;
  }
}

/* ! HEADER */
.header {
  background: #ffffff;
  padding: 15px 0;
  width: 100%;
}
.header #logo {
  display: block;
  margin: 0 auto;
  max-width: 120px;
}
@media (min-width: 992px) {
  .header {
    padding: 25px 0;
  }
  .header #logo {
    max-width: 180px;
  }
}





/* ! BODY */
/* ! BODY :: BANNER */
.section-banner img {
  width: 100%;
  margin: 0 auto;
  display: block;
}

/* ! BODY :: CONTENT */
.section-content {
  padding: 50px 0;
  text-align: center;
}
.section-content p {
  font-size: 18px;
  line-height: 1.2;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 50px;
}
.section-content .boxer-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: center;
  -ms-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.section-content .box-icon {
  margin: 0 auto 50px;
  width: 50%;
  text-decoration: none;
}
.section-content .box-icon .icon {
  margin-bottom: 20px;
}
.section-content .box-icon .icon img {
  margin: 0 auto;
  max-height: 55px;
}
.section-content .box-icon h5 {
  font-size: 22px;
  line-height: 1.2;
  color: #ffffff;
  font-weight: 400;
}
.section-content p.noted {
  font-size: 14px;
  line-height: 1.2;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 0;
}
.section-content sup {
  position: relative;
  top: 5px;
  line-height: 0;
  color: #ffffff;
}
@media (min-width: 992px) {
  .section-content p {
    font-size: 26px;
  }
  .section-content .box-icon .icon img {
    max-height: 95px;
  }
  .section-content .box-icon h5 {
    font-size: 30px;
  }
  .section-content p.noted {
    font-size: 20px;
  }
}
