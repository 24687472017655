.table-block__list-title {
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: #687182;
  padding: 20px;
  background-color: #F7F9FC;
  height: 54px;
}

.table-block__work-field {
  width: 100%;
  min-height: 90vh;
  background-color: #F7F9FC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  padding: 64px;
}

.icon-btn {
  border-radius: 0;
  width: 100%;
  border: none;
  background-color: transparent;
  outline-offset: 1.5px;
}
