@import "styles/custom.scss";

$section-item-icon-width: 14px;

.new-template-page {
  .breadcrumb-item + .breadcrumb-item::before {
    content: ">" !important;
  }

  .head-container {
    label {
      color: $fade-gray;
    }
  }

  .list-title {
    width: 100%;
    min-width: 500px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: #687182;
    background-color: #F7F9FC;
  }

  .edit-mode {
    font-size: 14px;
    font-weight: 500;
    color: $fade-gray
  }
}

.new-template-page-container {
  display: flex;
  border: 1px solid #E9EDF5;

  .section-top {
    min-width: 40%;
    border-right: 1px solid #E9EDF5;

    .header-content {
      background-color: #F7F9FC;
      display: flex;

      .section-title-item {
        position: relative;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        color: #687182;
        padding: 20px;
        height: 54px;

        &:hover {
          cursor: pointer;
        }

        &.active:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          width: 80%;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          border-bottom: 2px solid $primary;
        }
      }
    }
  }
}

.react-colorful {
  width: auto !important;
}

.section-list {
  width: 100%;
  list-style-type: none;
  font-weight: 500;
  letter-spacing: 0.02em;

  li {
    position: relative;
    border-bottom: 1px solid #E9EDF5;
  }

  .section-title {
    display: block;
    color: #009384;
    transition: all 0.3s ease;
    padding-left: 28px !important;
    padding-right: 28px !important;
    cursor: default;

    &.draggable {
      cursor: pointer;
    }

    &.dragging {
      opacity: 0.3 !important;
      cursor: grab;
    }

    &:hover {
      background: #E9EDF5;
    }
  }

  .section-item-icon {
    position: absolute;
    top: 20px;
    fill: #A1A9B8;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  .item-start-icon {
    left: 5px;
  }

  .item-action-icon-first {
    right: $section-item-icon-width;
  }

  .item-action-icon-second {
    right: $section-item-icon-width * 3;
  }

  .item-action-icon-third {
    right: $section-item-icon-width * 5;
  }

  .item-action-icon-fourth {
    right: $section-item-icon-width * 7;
  }

  li.active {
    .section-title {
      background-color: $primary;
      color: #fff;
      padding-right: 120px!important;
    }

    .arrow-icon {
      transform: rotate(180deg);
    }

    .sub-section-list {
      display: block;
    }

    .edit-block {
      display: flex;
    }
  }
}

.sub-section-list {
  display: none;
  list-style-type: none;
  padding: 0;
  margin: 0;

  span {
    display: inline-block;
    width: 50%;
    padding: 10px;

    &:first-child {
      background-color: #F9FAFD;
    }
  }
}

.edit-block {
  display: none;

  &__item {
    padding: 9px 8px;
    cursor: pointer;

    &:hover{
      background-color: rgba(0, 76, 156, 0.12);
    }

    &.selected {
      background-color: rgba(0, 76, 156, 0.12);
    }

    .item-title {
      color: $fade-gray;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.info-block {
  width: 100%;
}

.outlined-block {
  position: relative;
  margin: 65px auto;
  background: #dddddd;
  border: 1px dashed #A1A9B8;
  min-height: 695px;
  width: 635px;
}

.info-text {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    max-width: 200px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #A1A9B8;
  }
}

.background-grey {
  background-color: white;
}

.custom-form-input {
  min-width: 400px;
  height: 36px;
  border: none !important;
  font-size: 14px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(134, 143, 160, 0.16);
  border-radius: 6px;

  &:disabled {
    background-color: transparent !important;
    opacity: 0.7 !important;
  }
}

.custom-form-input-tiny{
  min-width: 320px;
  height: 26px;
  border-style: none;
  border-bottom: solid;
  border-color: rgba(76, 85, 103, 0.16);;
  font-size: 14px;

  &:focus{
    outline: none;
  }
}

.custom-form-select {
  min-width: 400px;
}

.custom-multiline-badge {
  background-color: #009384;
  color: white;
  border-radius: 5px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding: 0 5px 0 5px;
}